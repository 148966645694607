import { ProductSearchParams } from "@/app/products/_types";
import NoSSR from "@/components/NoSSR";
import { useSearch } from "@/context/SearchContext";
import useAnalytics from "@/hooks/useAnalytics";
import { useLinkPasting } from "@/hooks/useLinkPasting";
import { useHomeRevamp } from "@/hooks/useNewHomepage";
import usePhiaPathname from "@/hooks/usePhiaPathname";
import usePhiaSearchParams from "@/hooks/usePhiaSearchParams";
import { useUnauthedSearchCount } from "@/hooks/useUnauthedSearchCount";
import {
  useImageSearchUploadState,
  useScrapedProductState,
  useShopMoreImageUrlState,
} from "@/lib/graphql/state";
import { routes } from "@/routes";
import { MixpanelEventName } from "@/types";
import { resetCacheForQuery } from "@/utils/resetCache";
import { useApolloClient } from "@apollo/client";
import { useTailwindBreakpoint } from "@phiaco/phia-ui";
import { SearchIcon } from "@phiaco/phia-ui/dist/icons";
import cn from "classnames";
import { useRouter } from "next/navigation";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { addRecentSearch } from "../utils";
import RightSideContent from "./RightSideContent";
import SearchInputPlaceholder from "./SearchInputPlaceholder";
import SearchTag, { SearchTagProps } from "./SearchTag";
import SearchTagContainer from "./SearchTagContainer";
import { isValidUrl, normalizeUrl } from "./utils";

export interface SearchBarProps {
  className?: string;
  size?: "sm" | "lg";
  focusOnMount?: boolean;
  isLandingPage?: boolean;
  isHeader?: boolean;
}

const SearchBar: React.FC<SearchBarProps> = ({
  className,
  size = "sm",
  focusOnMount,
  isLandingPage,
  isHeader,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const router = useRouter();
  const searchParams = usePhiaSearchParams();
  const pathname = usePhiaPathname();
  const client = useApolloClient();
  const imageSearchBase64 = useImageSearchUploadState();
  const shopMoreUrls = useShopMoreImageUrlState();
  const { showSearchOverlay, hideSearchOverlay, searchTerm, setSearchTerm } =
    useSearch();
  const { scrapedProduct } = useScrapedProductState();
  const { isLinkPastingEnabled } = useLinkPasting();
  const { isRevampActive } = useHomeRevamp();
  const { isMobile } = useTailwindBreakpoint();
  const { logMixpanelEvent } = useAnalytics();
  const { setCookie } = useUnauthedSearchCount();
  const scrapedImageUrl = searchParams?.get(
    ProductSearchParams.SCRAPED_IMAGE_URL
  );

  const link = searchParams.get(ProductSearchParams.LINK) || "";
  const isLarge = size === "lg";

  const handleSearch = useCallback(() => {
    const value = searchTerm.trim();
    // Update local storage to include new search
    if (!value.length) {
      return;
    }

    // We intentionally clear any existing filters when new search
    const params = new URLSearchParams();
    // We have two different type of search i.e. Link search and text search, based on that we set different params
    if (isValidUrl(value) && isLinkPastingEnabled) {
      const normalizedUrl = normalizeUrl(value) ?? "";
      addRecentSearch(normalizedUrl);
      params.set(ProductSearchParams.LINK, normalizedUrl);
      // We are setting this param to true to show the custom price range filter when user opens the filter sidebar while loading
      params.set(ProductSearchParams.CUSTOM_PRICE_RANGE, "true");
    } else {
      addRecentSearch(value);
      params.set(ProductSearchParams.SEARCH, value);
    }
    setCookie();

    // Reset cache for products query
    resetCacheForQuery(client, "products");
    hideSearchOverlay();
    router.push(`${routes.PRODUCTS}?${params.toString()}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, isLinkPastingEnabled]);

  useEffect(() => {
    // synchronizes searchTerm value only on page load
    const termFromParams =
      searchParams.get(ProductSearchParams.SEARCH) || link || "";
    if (!searchTerm && termFromParams) {
      setSearchTerm(termFromParams);
    }
    // Only re-run this effect when link changes to avoid additional re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link]);

  const handleFocus = useCallback(
    (e: React.SyntheticEvent) => {
      logMixpanelEvent({ name: MixpanelEventName.SEARCH_BAR_CLICKED });
      const ev = e.nativeEvent;
      const target = ev.target as HTMLElement;
      if (
        (target instanceof HTMLButtonElement &&
          target.dataset.insideSearchInput === "true") ||
        target.dataset.insideImageSearchModal === "true"
      ) {
        e.preventDefault();
      }
    },
    [logMixpanelEvent]
  );

  const handleRemoveImageTag = useCallback(() => {
    const url = new URL(window.location.href);
    url.searchParams.delete(ProductSearchParams.IMAGE_URL);
    router.push(url.toString());
    logMixpanelEvent({
      name: MixpanelEventName.SEARCH_BAR_TAG_REMOVED,
      properties: { type: "image" },
    });
  }, [router, logMixpanelEvent]);

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        handleSearch();
        if (inputRef.current) {
          inputRef.current.blur();
        }
      } else if (event.key === "Escape") {
        if (inputRef.current) {
          inputRef.current.blur();
          // Hide search overlay
          hideSearchOverlay();
        }
      } else if (event.key === "Delete") {
        handleRemoveImageTag();
      }
    },
    [handleSearch, handleRemoveImageTag, hideSearchOverlay]
  );

  const handleClear = () => {
    setSearchTerm("");
    setShowPlaceholder(true);
  };

  const activeTags = useMemo(() => {
    const tags: SearchTagProps[] = [];
    const pairs = searchParams.keys();
    for (const key of pairs) {
      const value = searchParams?.get(key);
      // TODO: Update the condition when additional tag support is added
      // Image base64 string is stored in cache local state, not in URL
      if (
        key === ProductSearchParams.IMAGE_BASE_64 &&
        value === "true" &&
        imageSearchBase64
      ) {
        tags.push({
          searchParamKey: key,
          content: { imageSrc: imageSearchBase64 },
        });
      } else if (
        key === ProductSearchParams.SHOP_MORE &&
        shopMoreUrls &&
        shopMoreUrls.length > 0
      ) {
        tags.push({
          searchParamKey: key,
          content: { imageSrc: shopMoreUrls[0] },
        });
      } else if (key === ProductSearchParams.IMAGE_URL && value) {
        const content = {
          [key === ProductSearchParams.IMAGE_URL ? "imageSrc" : "text"]: value,
        } as SearchTagProps["content"];

        tags.push({
          searchParamKey: key,
          content,
        });
      }
    }
    return tags;
  }, [searchParams, imageSearchBase64, shopMoreUrls]);

  const [showPlaceholder, setShowPlaceholder] = useState(true);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setSearchTerm(value);
      setShowPlaceholder(!value && !activeTags.length);
    },
    [activeTags.length, setSearchTerm]
  );

  const openSearchOverlay = () => {
    if (
      (pathname.startsWith(routes.PRODUCTS) && !showPlaceholder) ||
      (isLinkPastingEnabled && !isRevampActive)
    ) {
      return;
    }
    showSearchOverlay();
  };

  useEffect(() => {
    setShowPlaceholder(!searchTerm && !activeTags.length);
  }, [searchTerm, activeTags.length]);

  // Used this effect to focus on mount
  useEffect(() => {
    if (focusOnMount) {
      inputRef.current?.focus();
    }
  }, [focusOnMount]);

  const isLandingPageAndRevampActive = useMemo(
    () => isLandingPage && isRevampActive,
    [isLandingPage, isRevampActive]
  );

  const hasSearchedWithLinkPastingEnabled = useMemo(
    () =>
      !showPlaceholder &&
      isLinkPastingEnabled &&
      searchTerm &&
      link &&
      isValidUrl(link) &&
      normalizeUrl(searchTerm) === normalizeUrl(link),
    [isLinkPastingEnabled, link, searchTerm, showPlaceholder]
  );

  const content = (
    <>
      <SearchTagContainer tags={activeTags} />
      <div
        className={cn(
          "relative mr-auto flex-1 bg-transparent sm:max-w-full",
          !isLandingPageAndRevampActive && "max-w-[calc(100%_-_50px)]",
          !isLandingPageAndRevampActive &&
            !hasSearchedWithLinkPastingEnabled &&
            "ml-2 sm:mx-4"
        )}>
        <NoSSR>
          {showPlaceholder && !isLinkPastingEnabled && (
            <SearchInputPlaceholder />
          )}
        </NoSSR>
        {hasSearchedWithLinkPastingEnabled && (
          <div className="mt-4">
            <SearchTag
              content={{
                text: `${new URL(link).host}`.replace("www.", ""),
                imageSrc: scrapedProduct?.imageUrls?.length
                  ? scrapedProduct?.imageUrls?.[0]
                  : (scrapedImageUrl ?? undefined),
              }}
              onClose={handleClear}
            />
          </div>
        )}
        <input
          type="text"
          inputMode="search"
          autoCorrect="off"
          ref={inputRef}
          value={searchTerm}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          spellCheck="false"
          autoComplete="off"
          placeholder={
            isLinkPastingEnabled ? "Paste product URL or search" : ""
          }
          aria-label="Search"
          className={cn(
            "h-full w-full tracking-tight placeholder-cn-tertiary outline-none placeholder-shown:truncate",
            hasSearchedWithLinkPastingEnabled &&
              "pointer-events-none select-none text-transparent"
          )}
        />
      </div>
      <RightSideContent
        handleSearchButtonClick={handleSearch}
        previewSearchTerm={searchTerm}
        handleClear={handleClear}
        size={size}
      />
    </>
  );

  return (
    <div
      className={cn(
        "flex items-center justify-between overflow-hidden focus-within:bg-bg-primary",
        "border border-border-transparent",
        isLinkPastingEnabled
          ? "bg-transparent focus-within:bg-transparent"
          : "bg-white",
        !isRevampActive
          ? "h-[44px] rounded-full"
          : isLandingPageAndRevampActive
            ? "rounded-[22px]"
            : "h-[3.5rem] rounded-[1.25rem] shadow-[0px_4px_48px_0px_rgba(0,0,0,0.10)] sm:h-[4.125rem]",
        className
      )}
      onClick={openSearchOverlay}
      onFocus={handleFocus}>
      {isLinkPastingEnabled ? (
        <div
          className={cn(
            "flex h-full items-center pl-lg pr-md text-[0.875rem] lg:text-[16px]",
            isLandingPage && "pr-md sm:h-[64px] md:text-[16px] lg:text-[22px]",
            isLandingPage && !isRevampActive ? "pl-xl" : "pl-lg",
            isRevampActive && "para-md",
            (isMobile || isHeader) && !isRevampActive
              ? "bg-bg-secondary"
              : "bg-white",
            isMobile && "pl-md pr-sm"
          )}>
          phia.com/
        </div>
      ) : (
        <SearchIcon
          className="pointer-events-none hidden min-w-4 text-cn-primary sm:ml-3 sm:block"
          size={isLarge ? 20 : undefined}
        />
      )}
      {isLinkPastingEnabled ? (
        <div
          className={cn(
            "flex h-full flex-1 items-center justify-between bg-white focus-within:bg-bg-primary sm:relative",
            isRevampActive ? "pr-md" : "pr-2",
            { "sm:h-[64px]": isLandingPage }
          )}>
          {content}
        </div>
      ) : (
        content
      )}
    </div>
  );
};

export default SearchBar;
