import { ProductSearchParams } from "@/app/products/_types";
import Button from "@/components/shared/Button";
import { MixpanelEventName } from "@/types";
import { CloseIcon } from "@phiaco/phia-ui";
import classNames from "classnames";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { useMemo } from "react";

export interface SearchTagProps {
  /**
   * Main "content" to display in search tag.
   *
   * 3 different cases for what content can be (in order of definition):
   * - requires only image
   * - requires only text
   * - requires both image and text
   */
  content:
    | {
        imageSrc: string;
        text?: never;
      }
    | {
        imageSrc?: never;
        text: string;
      }
    | {
        imageSrc: string;
        text: string;
      };
  searchParamKey?: string;
  onClose?: () => void;
}

const SearchTag = ({ searchParamKey, content, onClose }: SearchTagProps) => {
  const router = useRouter();

  const removeSearchParamKey = () => {
    if (!searchParamKey) return;
    const url = new URL(window.location.href);
    url.searchParams.delete(searchParamKey);
    // If the tag is an image tag, we track its removal using a query param
    if (searchParamKey === ProductSearchParams.IMAGE_BASE_64) {
      url.searchParams.set(ProductSearchParams.IMAGE_BASE_64, "false");
    }
    router.push(url.toString());
  };

  const imageSrc = useMemo(() => content?.imageSrc, [content]);
  const hasImage = !!imageSrc;

  return (
    <Button
      variant="transparent"
      eventName={MixpanelEventName.SEARCH_BAR_TAG_REMOVED}
      onClick={() => {
        if (searchParamKey) removeSearchParamKey();
        onClose?.();
      }}
      innerClassName="px-0 py-0 gap-sm"
      className="relative flex h-[36px] cursor-pointer overflow-hidden rounded-md bg-bg-secondary">
      {hasImage && (
        <Image
          width={36}
          height={36}
          src={imageSrc}
          alt={`Tag image`}
          className="h-full rounded-sm object-cover"
        />
      )}
      {content?.text && (
        <p
          className={classNames(
            "label-sm flex items-center",
            !hasImage && "ml-sm"
          )}>
          {content?.text}
        </p>
      )}
      <div className="pr-sm">
        <CloseIcon size={14} className="text-cn-tertiary" />
      </div>
    </Button>
  );
};

export default SearchTag;
