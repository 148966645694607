"use client";

import { ProductSearchParams } from "@/app/products/_types";
import useAnalytics from "@/hooks/useAnalytics";
import { useHomeRevamp } from "@/hooks/useNewHomepage";
import { setImageSearchUploadState } from "@/lib/graphql/state";
import { routes } from "@/routes";
import { MixpanelEventName } from "@/types";
import { CameraIcon, ImageIcon } from "@phiaco/phia-ui/dist/icons";
import cn from "classnames";
import { useRouter } from "next/navigation";
import {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useRef,
  useState,
} from "react";
import CircleButton from "../shared/CircleButton";
import ImageSearchModal from "./ImageSearchModal/ImageSearchModal";

interface Props {
  isSmall: boolean;
}

const ImageSearchButton: React.FC<Props> = ({ isSmall }) => {
  const [showImageSearchModal, setShowImageSearchModal] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null); // Create a ref for the file input
  const router = useRouter();
  const { logMixpanelEvent } = useAnalytics();

  const handleClick = (e: SyntheticEvent<HTMLButtonElement, Event>) => {
    e.preventDefault();
    e.stopPropagation();
    setShowImageSearchModal(true);
  };

  const handleCloseModal = () => {
    setShowImageSearchModal(false);
  };

  const handleNativeImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result && typeof reader.result === "string") {
          handleImageSearch(reader.result);
        } else {
          console.error("Error onloading image upload");
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageSearch = useCallback(
    (base64: string) => {
      logMixpanelEvent({
        name: MixpanelEventName.SEARCH_BY_IMAGE_SUBMIT,
        properties: { base_64: base64 },
      });
      setImageSearchUploadState(base64);
      const params = new URLSearchParams();
      params.delete(ProductSearchParams.SEARCH);
      router.push(
        `${routes.PRODUCTS}?${ProductSearchParams.IMAGE_BASE_64}=true`
      );
    },
    [logMixpanelEvent, router]
  );

  const { isRevampActive } = useHomeRevamp();

  return (
    <>
      <CircleButton
        eventName={MixpanelEventName.SEARCH_BAR_CAMERA_ICON_CLICKED}
        isTiny={isSmall}
        className={cn(
          "static h-12 w-12 rounded-lg bg-bg-tertiary",
          isSmall ? (!isRevampActive ? "h-8 w-8" : "h-10 w-10") : ""
        )}
        data-inside-search-input="true"
        onClick={handleClick}>
        {isRevampActive ? (
          <ImageIcon className="text-cn-onColor" size={22} />
        ) : (
          <CameraIcon className="text-cn-onColor" size={22} />
        )}
      </CircleButton>
      {/* Hidden input used for mobile upload */}
      <input
        type="file"
        accept="image/*"
        className="hidden"
        onChange={handleNativeImageUpload}
        ref={fileInputRef}
      />
      {showImageSearchModal ? (
        <ImageSearchModal
          isOpen={showImageSearchModal}
          onClose={handleCloseModal}
          handleImageSearch={handleImageSearch}
        />
      ) : null}
    </>
  );
};

export default ImageSearchButton;
