import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";

const searchQueries = [
  "Louis Vuitton",
  "Gucci",
  "Dior",
  "Prada bags",
  "Rolex watches",
  "Hermès leather",
  "Armani",
  "Ralph Lauren",
  "Versace",
  "Burberry",
];

const SearchInputPlaceholder = () => {
  const [index, setIndex] = useState(
    Math.floor(Math.random() * searchQueries.length)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      // Only update the index if the tab is visible to prevent any stale animations
      if (document.visibilityState === "visible")
        setIndex((index + 1) % searchQueries.length);
    }, 2500);

    return () => clearInterval(interval);
  }, [index]);

  const prefix = "Search";
  return (
    <motion.label
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="pointer-events-none absolute left-0 h-full w-full overflow-hidden text-left tracking-tight text-cn-tertiary outline-none">
      <span className="mr-1">{prefix}</span>
      <AnimatePresence mode="sync">
        <motion.span
          key={searchQueries[index]}
          initial={{ translateY: -25, opacity: 0 }}
          animate={{ translateY: 0, opacity: 1 }}
          exit={{ translateY: 25, opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="absolute w-[160px]">
          {searchQueries[index] + "..."}
        </motion.span>
      </AnimatePresence>
    </motion.label>
  );
};

export default SearchInputPlaceholder;
