import CircleButton from "@/components/shared/CircleButton";
import { useSearch } from "@/context/SearchContext";
import { useInternalUserState } from "@/lib/graphql/state";
import { LogEventProperties, MixpanelEventName } from "@/types";
import { CloseIcon, SearchIcon } from "@phiaco/phia-ui";
import { customCN as cn } from "@phiaco/phia-ui/dist/util";
import React, { memo } from "react";
import ImageSearchButton from "../ImageSearchButton";
import { SearchBarProps } from "./SearchBar";

interface Props {
  previewSearchTerm: string;
  handleSearchButtonClick: () => void;
  handleClear: () => void;
  size?: SearchBarProps["size"];
}

interface RightSideContentButtonProps {
  onClick: () => void;
  isSmall: boolean;
  eventName: MixpanelEventName | null;
  eventProperties?: LogEventProperties;
  children?: React.ReactNode;
}

const RightSideContentButton: React.FC<RightSideContentButtonProps> = ({
  onClick,
  isSmall,
  eventName,
  eventProperties,
  children,
}) => {
  return (
    <CircleButton
      key="search"
      eventName={eventName}
      eventProperties={eventProperties}
      onClick={onClick}
      variant="secondary"
      className={cn(
        "static h-12 w-12 rounded-lg bg-bg-tertiary",
        isSmall && "h-10 w-10"
      )}>
      {children}
    </CircleButton>
  );
};

const RightSideContent: React.FC<Props> = ({
  previewSearchTerm,
  handleClear,
  handleSearchButtonClick,
  size,
}) => {
  let rightSideContent = null;
  const isSmall = size === "sm";
  const { isSearchOverlayVisible: hasSearchOverlay, hideSearchOverlay } =
    useSearch();
  const internalUserEmail = useInternalUserState();
  const isInternalUser = !!internalUserEmail;
  const isGoogleShoppingImageSearchEnabled = isInternalUser;
  // Close the overlay immediately (to prevent rage clicks) and proceed with the search logic
  const handleSearchClick = () => {
    hideSearchOverlay();
    handleSearchButtonClick();
  };

  const searchInputHasValue = previewSearchTerm.length > 0;

  if (hasSearchOverlay) {
    rightSideContent = (
      <RightSideContentButton
        key="clear"
        eventName={MixpanelEventName.SEARCH_BAR_CLEARED}
        isSmall={isSmall}
        eventProperties={{ search_term: previewSearchTerm }}
        onClick={() => {
          hideSearchOverlay();
          handleClear();
        }}>
        <CloseIcon size={16} />
      </RightSideContentButton>
    );
  }
  if (searchInputHasValue) {
    rightSideContent = (
      <RightSideContentButton
        key="search"
        eventName={MixpanelEventName.SEARCH_BAR_SEARCH_CLICKED}
        eventProperties={{ search_term: previewSearchTerm }}
        onClick={handleSearchClick}
        isSmall={isSmall}>
        <SearchIcon size={16} />
      </RightSideContentButton>
    );
    // Hide image search capabilities once we launch google shopping search API
  } else if (isGoogleShoppingImageSearchEnabled && !hasSearchOverlay) {
    rightSideContent = <ImageSearchButton isSmall={isSmall} />;
  }

  return (
    <div className="flex items-center" onClick={e => e?.stopPropagation()}>
      {rightSideContent}
    </div>
  );
};

export default memo(RightSideContent);
