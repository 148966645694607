import Button from "@/components/shared/Button";
import ButtonLarge from "@/components/shared/ButtonLarge";
import { zIndex } from "@/constants";
import { MixpanelEventName } from "@/types";
import { Modal, PhotoIcon, useTailwindBreakpoint } from "@phiaco/phia-ui";
import imageCompression from "browser-image-compression"; // Import the library
import cn from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import Dropzone, { FileRejection, FileWithPath } from "react-dropzone";
import { ACCEPTED_IMAGE_UPLOAD_FILE_TYPES } from "./utils";

interface ImageSearchModalProps {
  handleImageSearch: (base64: string) => void;
  onClose: () => void;
  isOpen: boolean;
}

const ImageSearchModal = ({
  handleImageSearch,
  onClose,
  isOpen,
}: ImageSearchModalProps) => {
  const [error, setError] = useState<string | null>(null);
  const [file, setFile] = useState<(FileWithPath & { preview: string }) | null>(
    null
  );
  const { isMobile } = useTailwindBreakpoint();
  const cropperRef = useRef<ReactCropperElement>(null);

  const handleClose = useCallback(() => {
    onClose();
    setFile(null);
    setError(null);
  }, [onClose, setFile, setError]);

  useEffect(() => {
    return () => {
      if (cropperRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        cropperRef.current.cropper.destroy();
      }

      if (file) {
        URL.revokeObjectURL(file.preview);
      }
    };
    // TODO: investigate if we need to disable this
    // Disabling to only trigger once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Reset file and error state when the modal is closed
    if (!isOpen) {
      handleClose();
    }
  }, [isOpen, handleClose]);

  const handleError = (err: Error) => {
    setError(err.message);
  };

  const handleDrop = useCallback(
    async (acceptedFiles: FileWithPath[], fileRejections: FileRejection[]) => {
      if (acceptedFiles.length === 0) {
        if (fileRejections.length > 0) {
          const rejectedFile = fileRejections[0].file;
          const fileName = rejectedFile.name;
          const fileExtension = fileName.substring(fileName.lastIndexOf("."));
          setError(
            `The file type ${fileExtension} is not supported. We support the following file types: \n ${ACCEPTED_IMAGE_UPLOAD_FILE_TYPES.join(", ")}`
          );
        } else {
          setError("This file type is not supported.");
        }
        console.error("No accepted files to upload, exiting...");
        return;
      }

      const file = acceptedFiles[0];

      // Compress the image
      try {
        const compressedFile = await imageCompression(file as File, {
          maxSizeMB: 5, // Set the maximum file size (in MB)
          maxWidthOrHeight: 1024, // Set the maximum width/height to maintain aspect ratio
          useWebWorker: true, // Use web workers for faster processing
        });

        const fileWithPreview = Object.assign(compressedFile, {
          preview: URL.createObjectURL(compressedFile),
        });

        setFile(fileWithPreview);
        setError(null);
      } catch (compressionError) {
        console.error("Image compression failed:", compressionError);
        setError("Failed to compress the image. Please try again.");
      }
    },
    []
  );

  const handleCrop = async () => {
    if (!file || !cropperRef.current) return;

    try {
      const cropper = cropperRef.current.cropper;
      const croppedCanvas = cropper.getCroppedCanvas({
        fillColor: "#fff", // Fill transparent area with white
      });
      const croppedImageBase64 = croppedCanvas.toDataURL();
      handleImageSearch(croppedImageBase64);
      onClose();
      setFile(null);
      setError(null);
    } catch (error: unknown) {
      if (error instanceof Error) {
        setError(error.message);
        console.error("Error cropping image:", error);
      } else {
        setError("An unexpected error occurred.");
        console.error("Unexpected error cropping image:", error);
      }
    }
  };
  const isFullHeight = !!file && isMobile;

  return (
    <Modal
      zIndex={zIndex.MODAL_OVERLAY}
      isOpen={isOpen}
      omitMobileStyles={true}
      heading={
        <div className="flex flex-col gap-2">
          <div className="heading-serif-sm">Search by image</div>
          <div className="para-md">
            For the best results, crop the image to focus on the item you are
            searching for.
          </div>
          {error && (
            <p className="para-sm whitespace-pre-wrap pt-2 font-medium text-cn-negative">
              {error}
            </p>
          )}
        </div>
      }
      includeCloseBtn={true}
      className={cn(
        "w-full sm:min-h-[500px] sm:max-w-[800px]",
        isFullHeight ? "top-4 h-full max-h-[95%] min-h-[500px]" : ""
      )}
      onClose={onClose}>
      {!file ? (
        <Dropzone
          onDrop={handleDrop}
          onError={handleError}
          maxFiles={1}
          accept={{
            "image/*": ACCEPTED_IMAGE_UPLOAD_FILE_TYPES,
          }}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <section className="cursor-pointer">
              <div
                className={cn(
                  "w-ful border-disabled flex h-[200px] flex-col items-center justify-center rounded-sm border border-dashed md:h-[400px]",
                  { "border-cn-accent bg-bg-neutral": isDragActive }
                )}
                {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="flex flex-col items-center gap-4">
                  <PhotoIcon />
                  <p className="label-md text-center">
                    Drop any image to search or{" "}
                    <span className="text-cn-accent">upload</span>.<br />
                  </p>
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      ) : (
        <div className="relative">
          <Cropper
            ref={cropperRef}
            src={file.preview}
            zoomable={false}
            style={{
              height: isMobile ? undefined : 400,
              width: "100%",
              background: "white",
            }}
            responsive={false}
            guides={false}
            background={false}
            viewMode={2}
          />
          {isMobile ? (
            <div className="fixed bottom-0 left-0 flex w-full flex-col gap-2 p-4 pt-4 sm:hidden sm:p-8">
              <ButtonLarge
                variant="mono"
                className="mx-auto"
                eventName={MixpanelEventName.SEARCH_BY_IMAGE_SUBMIT}
                onClick={handleCrop}>
                Search
              </ButtonLarge>
              <ButtonLarge
                variant="secondary"
                className="mx-auto"
                eventName={
                  MixpanelEventName.SEARCH_BY_IMAGE_CHANGE_IMAGE_CLICKED
                }
                onClick={() => setFile(null)}>
                Change image
              </ButtonLarge>
            </div>
          ) : (
            <>
              <Button
                variant="secondary"
                eventName={null}
                // Safari doesn't support h-fit on absolute elements so we need to set a min height
                className="absolute left-4 top-4 min-h-7"
                onClick={() => setFile(null)}>
                Change image
              </Button>
              <Button
                variant="primary"
                // Safari doesn't support h-fit on absolute elements so we need to set a min height
                className="absolute right-4 top-4 min-h-7"
                eventName={MixpanelEventName.SEARCH_BY_IMAGE_SUBMIT}
                onClick={handleCrop}>
                Search
              </Button>
            </>
          )}
        </div>
      )}
    </Modal>
  );
};

export default ImageSearchModal;
