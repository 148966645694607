import SearchTag, { SearchTagProps } from "./SearchTag";

interface SearchTagContainerProps {
  tags: SearchTagProps[];
}

const SearchTagContainer = ({ tags }: SearchTagContainerProps) => {
  return (
    <div className={`flex gap-2 ${tags.length > 0 && "px-2"}`}>
      {tags.map((tag: SearchTagProps) => (
        <SearchTag key={`${tag.searchParamKey}-${tag.content}`} {...tag} />
      ))}
    </div>
  );
};

export default SearchTagContainer;
