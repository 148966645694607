export const ACCEPTED_IMAGE_UPLOAD_FILE_TYPES = [
  ".png",
  ".jpeg",
  ".jpg",
  ".gif",
  ".bmp",
  ".webp",
  ".avif",
  ".tiff",
  ".svg",
];
